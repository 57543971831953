<template>
  <div class="Question">
    <ValidationObserver ref="form">
      <b-card>
        <div>
          <!--add & details question-->
          <ek-input-select
            label="تابع للفيديو - للملف"
            placeholder="اختر الملف "
            name="lessonId"
            textLabel="followTo"
            :options="lesson.lessons"
            v-model="questionDto.lessonId"
          ></ek-input-select>

          <ek-input-text
            :rules="[{ type: 'required', message: 'نص السؤال  مطلوب' }]"
            placeholder="ادخل نص السؤال"
            label="نص السؤال"
            name="questions"
            v-model="questionDto.question"
          ></ek-input-text>

          <ek-input-text
            label=" التلميح"
            placeholder="ادخل  تلميح معين"
            name="hint"
            v-model="questionDto.hint"
          ></ek-input-text>

          <div class="w-100 mt-2 rounded">
            <h4 for="question-text">
              إضافة إجابة
              <b-button
                variant="primary"
                class="p-0 b-icon"
                @click="addAnswer(questionDto)"
              >
                <unicon fill="white" height="18px" name="plus"> </unicon>
              </b-button>
            </h4>
            <div
              class="main-border w-100 p-1 rounded"
              v-for="(ans, index) in questionDto.answers"
              :key="index"
            >
              <div class="d-flex justify-content-between">
                <div class="d-flex">
                  <label>{{ `الإجابة ${index + 1}` }}</label>
                  <b-form-checkbox
                    v-b-tooltip.hover
                    title="الإجابة الصحيحة"
                    class="ml-1"
                    v-model="ans.isCorrect"
                    name="checkbox-1"
                  >
                  </b-form-checkbox>
                </div>
              </div>
              <div class="position-relative">
                <ek-input-text
                  :rules="[
                    {
                      type: 'required',
                      message: 'الإجابة   مطلوبة',
                    },
                  ]"
                  placeholder="ادخل الاجابة"
                  name="addQuestion"
                  class="w-75"
                  v-model="ans.answer"
                >
                </ek-input-text>
                <!--Add button delete ans-->
                <b-button
                  variant="wight"
                  class="position-absolute svg-delete"
                  @click="deleteAns(questionDto, index)"
                >
                  <svg
                    style="cursor: pointer"
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.50016 17.4163C13.8724 17.4163 17.4168 13.8719 17.4168 9.49968C17.4168 5.12742 13.8724 1.58301 9.50016 1.58301C5.12791 1.58301 1.5835 5.12742 1.5835 9.49968C1.5835 13.8719 5.12791 17.4163 9.50016 17.4163Z"
                      stroke="#EA5455"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.875 7.125L7.125 11.875"
                      stroke="#EA5455"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.125 7.125L11.875 11.875"
                      stroke="#EA5455"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="ml-1 mt-3 d-flex justify-content-end">
          <b-button size="md" class="ml-1" variant="primary" @click="submit">
            {{ id != 0 ? "تعديل" : "اضافة" }}
          </b-button>

          <b-button variant="outline-primary" class="ml-1" @click="$router.go(-1)">
            تراجع
          </b-button>

          <b-button
            variant="outline-danger"
            class="ml-1"
            v-if="this.id != 0"
            @click="deleteQues(questionDto.id)"
            >حذف</b-button
          >
        </div>
      </b-card>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: ["id", "quizId"],
  computed: {
    ...mapState({
      lesson: ({ lessons }) => lessons.lesson,
      questionDto: ({ questionBank }) => questionBank.questionDto,
    }),
  },
  data() {
    return {
      mainQuestions: [],
    };
  },
  methods: {
    ...mapActions(["getQuestionDetails", "addQuestion", "updateQuestion", "getLessons"]),
    //submit add | update question
    submit() {
      this.$refs.form.validate().then((succ) => {
        if (succ) {
          if (this.id != 0) {
            this.updateQuestion(this.questionDto).then((res) => {
              this.questionDto = {
                ...res,
              };
            });
          } else {
            this.addQuestion({
              ...this.questionDto,
            }).then((data) => {
              this.mainQuestions.push({
                ...this.questionDto,
              });
              this.$store.commit("Add_Questions_Lessons", data);
            });
          }
        }
      });
    },

    deleteQues(id) {
      this.$store.dispatch("deleteQuestion", [id]);
    },

    //push answer to list answers
    addAnswer(target) {
      target.answers.push({
        isCorrect: false,
        qusetion: "",
      });
    },
    
    deleteAns(item, id) {
      let s = item.answers.findIndex((ele, index) => index == id);
      item = item.answers.splice(s, 1);
    },
  },
  created() {
    if (this.id != 0) {
      this.getQuestionDetails(this.id).then((res) => {
        this.$store.commit("Get_Question_DETEAILS", res);
      });
    }
    this.getLessons({
      lessonType: "",
      pageIndex: 1,
      pageSize: 10,
    });
  },
  beforeDestroy() {
    this.$store.commit("RESET_QUESTION_DETAILS");
  },
};
</script>
<style lang="scss">
.Question {
  .main-border {
    padding: 20px;
    border: 3px solid #eee;
    border-radius: 5px;

    .border-line {
      border: 3px solid #eee;

      h4 {
        border: 1px solid rgb(220, 220, 220);
        padding: 7px 15px;
        border-radius: 5px;
        margin-bottom: 0 !important;
      }
    }
  }

  .svg-delete {
    right: 0px;
    top: 25%;
  }
}

.b-icon {
  margin-left: 3px;
}

.b-icons {
  height: 21px;
  position: relative;
  left: 3px;
}

.questions-bank {
  align-items: center;

  &__filter-title {
    font-weight: bold;
    font-size: 1.2rem;

    color: #6e6b7b;
  }

  &__filter-container {
    display: flex;
    gap: 2rem;
  }

  &__filter-by-activity-container {
    display: flex;
  }

  &__activity-filter-title {
    margin-top: 1.4rem;
    font-size: 1rem;

    color: #6e6b7b;
  }

  &__activity-filter-input {
    flex: 1;
  }

  &__filter-by-ads-status-container {
    display: flex;
  }

  &__status-filter-title {
    margin-top: 1.4rem;

    font-size: 1.2rem;

    color: #6e6b7b;

    @media only screen and (max-width: 780px) {
      margin-left: 0px;
    }
  }

  &__status-filter-input {
    flex: 0.3;
  }
}
</style>
